<template>
  <div>
    <router-view></router-view>
    <Affiliated></Affiliated>
  </div>
</template>

<script>
import Affiliated from "@/views/components/Affiliated"
export default {
  name: "tour",
  components:{
    Affiliated
  },
  mounted() {
    this.$store.dispatch("updatePageHead", "事件")
  }
}
</script>

<style>

</style>